import React from 'react';
import ReactDOM from 'react-dom/client';
import InstoryComponent from './components/instory';

export const isDev = process.env.NODE_ENV === 'development';

const RenderBlock = document.getElementById('react-story-module') as HTMLElement;

if (RenderBlock) {
  const root = ReactDOM.createRoot(RenderBlock);
  const enableMobile = RenderBlock.getAttribute('data-enable-mobile');
  const enableDesktop = RenderBlock.getAttribute('data-enable-desktop');


  root.render(
    <div>
      <InstoryComponent enableMobile={enableMobile? JSON.parse(enableMobile) : false} enableDesktop={enableDesktop? JSON.parse(enableDesktop) : false} />
    </div>,
  );
}
